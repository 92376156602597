.faq-item{
    margin-top: 10px;
    .question{
        font-size: 1.2em;
        span{
            position: relative;
            &::after{
                content: '';
                background: url('/images/arrow-down.svg');
                background-size: cover;
                height: 10px;
                width: 10px;
                position: absolute;
                right:  -25px;
                bottom: calc(50% - 5px);
            }
            &.active{
                &::after{
                transform: rotate(180deg);
            }
            }
        }
    }
}
