body {
  margin: 0;
  padding: 0;
  font-size: 62.5% !important;
  background: #282731;
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
}

* {
  font-family: "Ubuntu", sans-serif;
}
.padding-vertical-unset {
  padding-top: 0;
  padding-bottom: 0;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.m-top-25 {
  margin-top: 25px;
}
.m-left-25 {
  margin-left: 25px;
}
.w-100 {
  width: 100%;
}

.green {
  color: green !important;
}

.yellow {
    color: #FFC400 !important;
}

.red {
    color: #FF5660 !important;
}

.title {
  font-size: 1.5em;
}
.btn-small {
  width: unset !important;
  padding-right: 30px !important;
}
// mudar
.row_lists {
  justify-content: space-between;
  display: flex;
}
.list-box {
  flex-direction: row;
  flex: 1;
  margin-left: 25px;
  margin-top: 25px;
}
.table-list {
  flex-basis: 100%;
}
.table-list .header {
  margin-top: 20px;
}
.map {
  img {
    max-width: 66%;
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}
.table-list ul {
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px 20px;
  justify-content: space-between;
  margin-bottom: 10px;
  li {
    list-style: none;
    color: #a0a0a0;
    flex: 1;
    font-size: 1em;
  }
}
.table-items {

  &.latest-orders {
    ul {
      li {
        font-size: 0.7rem;
      }
    }
  }

  ul {
    background: #3E3D46;
    border-radius: 8px;
    li {
      color: #fff;
    }
  }
}

// mudar
.finance-bottom {
  display: flex;
  flex-wrap: wrap;
}
.finance-box {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.separator {
  width: calc(100% + 40px);
  height: 2px;
  background: #282731;
  margin: 20px 0;
}
.box-finance-bottom {
  display: flex;
  flex-direction: row;
  .content {
    color: #808080;
  }
}

// mudar
.faq {
  width: 47.5%;
  padding-right: 10px;
  border-right: 2px solid #282731;
}
.help {
  flex: 1;
  padding-left: 20px;
  .title {
    margin-bottom: 25px;
  }
}
.list-with-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 200px;
  align-items: center;
  max-width: 480px;
  margin: 0 auto;
  .item {
    color: #808080;
    height: 80px;
    padding: 0 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    img {
      width: 40px;
      margin-bottom: 10px;
    }
    &:nth-of-type(2n) {
      border-left: 2px solid #282731;
      border-right: 2px solid #282731;
    }
  }
}

// mudar
.map {
  flex-direction: column;
}

.list-transactions {
  flex-direction: column;
}
.pagination-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

// mudar
.content-box_header {
  display: flex;
  justify-content: space-between;
  .selects {
    display: flex;
  }
}
