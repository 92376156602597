.content-container{
    left: 107px;
    position: relative;
    width: calc((100% - 107px));
    padding: 25px;
    background: #282731;
    min-height: 100vh;
    .btn{
        width: 156px;
        color: #fff;
        background: #F4747E;
        border-radius: 19px;
        height: 35px;
        position: relative;
        border: none;
        font-size: 1.2em;
        font-weight: 600;
        text-align: left;
        padding-left: 15px;
        img{
            width: 22px;
            position: absolute;
            right: 5px;
            top: calc(50% - 11px);
        }
    }
    .arrow-right{
        img{
            transform: rotate(-90deg);
        }
    }
    .search-wrapper{
        width: 300px;
        padding: 0 10px;
        background: #3E3D46;
        border-radius: 8px;
        position: relative;
        margin-left: 10px;
        .search-icon{
            height: 12px;
            width: 12px;
            position: absolute;
            right: 10px;
            top: calc(50% - 6px);
        }
        input{
            border: none;
            width: 100%;
            height: 42px;
            color: #fff;
            background: transparent;
        }
    }
    .content-box{
        background: #16161B;
        padding: 20px;
        border-radius: 8px;
        display: inline-flex;
    }
    .info-label{
        width: 100%;
        display: flex;
        flex-direction: column;
        .label{
            font-size: 1.2em;
        }
        .value{
            font-size: 2em;
        }
    }
    .pagination{
        width: 100px;
        border-radius: 30px;
        background: #3E3D46;
        align-items: center;
        font-size: 1.2em;
        justify-content: space-between;
        padding: 2px;
        margin-top: 20px;
        .arrow{
            &.left{
                transform: rotate(90deg);
            }
            &.right{
                transform: rotate(-90deg);
            }
            width: 25px;
        }
        img{
            width: 100%;
        }
    }
}
.select-default{
    display: flex;
    align-items: center;
    margin-right: 20px;
    position: relative;
    padding-right: 20px;
    &:last-of-type{
        margin-right: 0;
    }
        &::after{
            content: '';
            width: 10px;
            height: 10px;
            background-image: url('/images/arrow-down.svg');
            background-size: cover;
            position: absolute;
            right: 0;
        }
    .select-label{
        font-size: 1.2em;
        color: #a0a0a0;
        margin-right: 1em;
    }
    select{
        appearance: none;
        background: transparent;
        border: none;
        color: #fff;
        outline: none;
        
    }
}

.input-wrapper{
    input{
        background: #3E3D46;
        width: 220px;
        height: 27px;
        border: none;
        border-radius: 5px;
        color: #fff;
        outline: none;
        padding: 0 15px;
    }
}

.content-container-mobile {
    background: #fcfafa;
    height: 100%;
}

@media screen and (min-width: 701px) {
    .content-container-mobile {
      display: none;
    }
}
