.sidebar{
    width: 107px;
    background: #16161B 0% 0% no-repeat padding-box;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    .user-avatar{
        .avatar-photo{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            display: inline-flex;
            img{
                width: 100%;
            }
        }
        .name{
            text-align: center;
            color: #fff;
            margin-top: 20px;
            font-size: 1.2em;
            &:last-of-type{
                margin-top: 5px;
            }
            strong{
                font-weight: 700;
            }
        }
    }
    .menu_items{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .menu-nav_item{
            width: 100%;        
            margin-top: 20px;
            text-align: center;
            text-decoration: none;
            color: #fff;
            .label{
                margin-top: 5px;
            }
            &:hover{
                text-decoration: none;
            }
            &.active{
                color: #FF7276;
                animation-duration: 1s;
                animation-name: opacityIn;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 2px;
                    background: #FF7276;
                    right: 0;
                    top:0;
                    animation-duration: 1s;
                    animation-name: growVertical;
                }
            }
            &.active *{
                fill: #FF7276;
            }
        }
    }
}

@keyframes opacityIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}


@keyframes growVertical{
    from{
        height: 0;
        top: 50%;
    }
    to{
        top: 0;
        height: 100%;
    }
}
