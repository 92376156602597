.content-container .content-box.report-charts-wrapper{
    padding: 0 20px;
}
.report-chart{
    .progress, .progress-bar {
        height: 7px;
        width: 75%;
    }
    .apexcharts-gridline {
        stroke: #282731;
    }
    display: flex;
    flex-basis: 100%;
    &.full{
        .box-chart{
            width: 100%;
            border: none;
        }
        .report-chart_top{
            padding: 0 30px;
        }
    }
    .apexcharts-toolbar{
        display: none;
    }
    .apexcharts-xaxistooltip{
        color: #fff!important;
    }
    .apexcharts-tooltip.light {
        background: #16161B !important;
        .apexcharts-tooltip-title {
            background: #16161B !important;
        }
    }
    .apexcharts-xaxistooltip {
        background: #16161B !important;
    }
    .box-chart{
        width: calc(65% - 40px);
        padding: 20px 0;
        border-right: 2px solid #282731;
        padding-right: 20px;
    }
    .report-chart_top{
        display: flex;
        justify-content: space-between;
        .title{
            font-size: 1.5em;
        }
        .right{
            display: flex;
        }
    }
    .report-totals{
        flex: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        .label-top{
                font-size: 1.2em;
                span{
                    color: rgba($color: #c0c0c0, $alpha: 0.4)
                }
            }
        .list{
            display: flex;
            margin-top: 2em;
            width: 100%;
            flex-wrap: wrap;
            .list-item{
                flex-basis: 50%;
                margin-bottom: 2em;
                .list-item-label{
                    font-size: 1.2em;
                }
                .list-item-value{
                    font-size: 2em;
                    font-weight: 700;
                }
            }
        }

            .perfomance{
                margin-bottom: 2em;
                display: flex;
                flex-direction: row;
                align-items: center;
                .right{
                    width: 65%;
                    color: #a0a0a0;
                }
                .left{
                    flex: 1;
                }
                .label{
                    font-size: 1.2em;
                }
                .value{
                    font-size: 2em;
                    font-weight: 700;
                }
            }
    }
}
.metrics{
    margin-top: 30px;
    border-top: 2px solid #282731;
    padding-top: 30px;
}