.form-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 281px;
}
.form-group {
    display: block;
    margin-right: auto;
    margin-left: auto;
    label {
        height: 13px;
        text-align: left;
        font: Medium 12px/13px Ubuntu;
        letter-spacing: 0;
        color: #282731;
        opacity: 1;
    }
    input {
        display: block;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        height: 35px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;
    }
}