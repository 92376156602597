.form-button {
    display: block;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 282px;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 8px #0000003D;
    border: 1px solid #EBEBEB;
    border-radius: 3px;
    opacity: 1;

    text-align: center;
    font: Bold 16px/18px Ubuntu;
    letter-spacing: -0.29px;
    color: #FF7276;
}