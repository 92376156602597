.transactions_details {
    .left, .right{
        width: calc(50% - (25px / 2));
    }
}
.box-details{
    background: #16161B;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 25px;
    .header{
        padding: 20px;
        color: #ffffff;
        font-size: 1.5em;
        border-bottom: 2px solid #282731;
    }
    .details-items{
        padding: 20px;

        ol.event-items {
            list-style-type: none;
          
          li.event-item {
            position: relative;
            margin: 0;
            padding-bottom: 3em;
            padding-left: 20px;
            font-size: 1.2em;

            ol.content {
                li {
                    color: #748591;
                }

                li.description {
                    color: #ffffff;
                }
                
            }

            ol.ghost-content li {
                padding-top: 0.5rem;
            }
          }
          
          li.event-item:after {
            content: '';
            background-color: #748591;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 6px;
            width: 3px;
          }
          
          li.event-item:before {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23748591' cx='16' cy='16' r='12'%3E%3C/circle%3E%3C/svg%3E");
            position: absolute;
            left: 0;
            height: 15px;
            width: 15px;
          }
        }

        .last {
            padding-bottom: 0rem !important;
        }
        
        .detail-item{
            display: flex;
            margin-bottom: 15px;
            font-size: 1.2em;
            .label{
                color: #748591;
                width: 30%;
            }
            .value{
                color: #ffffff;
            }
        }

        .refund-feedback {
            min-height: 1rem;
        }
    }
    .details-button{
        width: 100%;
        display: flex;
        justify-content: center;
        .btn{
            text-align: center;
            width: 170px;
        }
    }

}