.topbar-mobile{
    display: flex;
    justify-content: left;
    padding: 20px;
    margin-bottom: 25px;
    background: #fcfafa;
    box-shadow: 0px 6px 10px #00000029;
    .title {
        text-align: left;
        font: Bold 12px/13px Ubuntu;
        letter-spacing: 0;
        color: #282731;
        opacity: 1;
    }
    img {
        width: 11px;
        height: 13px;
        margin-right: 10px;
        opacity: 1;
    }
}