.plan-box {
    cursor: pointer;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 32px;
    width: 305px;
    height: 100px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    box-shadow: 6px 6px 10px #00000029;
    border: 2px solid #282731;
    border-radius: 5px;
    opacity: 1;
    .title {
        text-align: center;
        font: Bold 14px/16px Ubuntu;
        letter-spacing: 0;
        color: #282731;
        opacity: 1;
    }
    .value {
        text-align: center;
        font: Light 12px/13px Ubuntu;
        letter-spacing: 0;
        color: #282731;
        opacity: 1;
    }
}

.dark {
    .plan-box {
        background: #282731 0% 0% no-repeat padding-box;
        border: 2px solid #282731;
        .title {
            color: #fff;
        }
        .value {
            color: #fff;
        }
    }
}

.page-title {
    margin-top: 40%;
    margin-bottom: 20px;
    text-align: center;
    font: Bold 16px/18px Ubuntu;
    letter-spacing: 0;
    color: #282731;
    opacity: 1;
}