.payment-container {
  padding-top: 50px;
}

.payment-form {
  margin-top: 50px;
}

.payment-processing-container,
.payment-confirmed-container {
  margin-top: 200px;
  .visa {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    width: 75px;
    height: 75px;
    background: #282731 0% 0% no-repeat padding-box;
    box-shadow: 6px 6px 10px #00000029;
    border: 2px solid #282731;
    border-radius: 100px;
    opacity: 1;
    img {
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-top: 30px;
      height: 15px;
      opacity: 1;
    }
  }

  .confirmed {
    background: #fff;
    width: 75px;
    height: 75px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    img {
      width: 75px;
      height: 75px;
    }
  }

  .processing-payment-text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 148px;
    height: 13px;
    text-align: center;
    font: Bold 12px/13px Ubuntu;
    letter-spacing: 0;
    color: #282731;
    opacity: 1;
  }
}
