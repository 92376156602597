.modal-content {
    background: #16161B 0% 0% no-repeat padding-box !important;
    border-radius: 6px;
    opacity: 1;
    .h4, h4 {
        font-size: 1.5em;
    }
    .modal-header {
        border-bottom: 2px solid #282731 !important;
    }
    .modal-title {
        text-align: left;
        font: Regular 12px/13px Ubuntu;
        letter-spacing: 0;
        color: #FFFFFF;
        opacity: 1;
    }
    .btn{
        width: 156px;
        color: #fff;
        background: #F4747E;
        border-radius: 19px;
        height: 35px;
        position: relative;
        border: none;
        font-size: 1.2em;
        text-align: center;
        img{
            width: 22px;
            position: absolute;
            right: 5px;
            top: calc(50% - 11px);
        }
    }
    .arrow-right{
        img{
            transform: rotate(-90deg);
        }
    }
    .modal-body {
        padding-bottom: 0rem;
    }
    .box-payment-request{
        background: #16161B;
        border-radius: 8px;
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 25px;
        .payment-request-items{
            padding: 20px;
            .payment-request-item{
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                font-size: 1.2em;
                .label{
                    color: #748591;
                    width: 30%;
                }
                .value{
                    .value-text {
                        width: 220px;
                    }
                    color: #ffffff;
                }
            }
        }
    }
    .payment-request-button{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .btn{
            text-align: center;
            width: 170px;
            margin-right: 22px;
        }
    }
}